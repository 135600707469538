import React, { useState, useEffect, createElement } from 'react';
import { getAllBrands } from '../../containers/CategoriesContainer';

const Brands = () => {
    const [allBrands, setAllBrands] = useState([]);

    useEffect(() => {
        setAllBrands(getAllBrands());
    }, []);

    return(
        <>
            <div className="container d-flex align-items-center flex-wrap justify-content-center">
                <ul className="d-flex flex-row list-unstyled">
                    {allBrands?.length > 0 && allBrands?.length != null ? 
                    allBrands
                    .map(brand => (
                        brand.LinkImagem ? (
                            <li className="box blkTipBox" key={`brand${brand.idClassificacaoNivel}`}>
                            {createElement('a', { href: `/busca?b=${brand.idClassificacaoNivel}`, key:`brand-a${brand.idClassificacaoNivel}` },
                                <img alt='' src={brand.LinkImagem} />)
                            }
                        </li>
                        ): null
                      
                    ))
                    : ""
                    }
                </ul>
            </div>
        </>
    );
}

export default Brands